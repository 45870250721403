<script>

import Icon from "../../Icon";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_2 from "../default/DefaultCard_2";
import GerkonCard_1Table from "./GerkonCard_1Table";
import GerkonCard_2LatestValue from "./GerkonCard_2LatestValue";
import GerkonCard_2MetricsButtons from "./GerkonCard_2MetricsButtons";

export default {
    name: "GerkonCard_2",
    extends: DefaultCard_2,
    components: {
        HeaderComponent: DefaultCard_1Header,
        MetricsButtonsComponent: GerkonCard_2MetricsButtons,
        LatestValueComponent: GerkonCard_2LatestValue,
        ChartComponent: GerkonCard_1Table,
        Icon
    },
    computed: {
        /**
         * @override
         * @returns {{"background-image": string}|null}
         */
        metricImage() {
            let iconFile;
            let deviceSlug = this.deviceObject?.getType()?.getSlug();

            try {
                iconFile = require(`../../../assets/metrics/${deviceSlug}.png`);
            } catch (e) {
                iconFile = require(`../../../assets/metrics/default.png`);
            }

            return {
                "background-image": `url(${iconFile})`
            }
        },
    }
}
</script>
