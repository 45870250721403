<script>

import Icon from "../../Icon";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_3 from "../default/DefaultCard_3";
import GerkonCard_1Table from "./GerkonCard_1Table";
import GerkonCard_3ValuesList from "./GerkonCard_3ValuesList";

export default {
    name: "DefaultCard_3",
    extends: DefaultCard_3,
    components: {
        HeaderComponent: DefaultCard_1Header,
        ValuesListComponent: GerkonCard_3ValuesList,
        ChartComponent: GerkonCard_1Table,
        Icon
    },
}
</script>
