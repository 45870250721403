<template>
    <div ref="latestValue" class="latest-value animate__animated">
        <div class="latest-value__bg" :style="deviceImage">
            <div v-if="value !== null">
                <div v-if="currentMetric.getSlug() === 'status'"
                     class="d-flex justify-content-center align-content-center text-center">
                    <div>
                        <div class="status-icon mx-auto">
                            <i v-if="status" class="fas fa-lg fa-unlock text-danger"></i>
                            <i v-else class="fas fa-lg fa-lock text-success"></i>
                        </div>
                        <div class="d-flex justify-content-center align-items-center font-weight-bold mt-1 text-uppercase text-white">
                            {{ status ? $t('Открыто') : $t('Закрыто') }}
                        </div>
                    </div>
                </div>
                <div v-else-if="currentMetric.getSlug() === 'times'">
                    <div class="latest-value__value">{{ Math.round(value) }}</div>
                </div>
                <div v-else>
                    <div class="latest-value__value">{{ Math.round(value) }}</div>
                    <div class="latest-value__units">{{ units }}</div>
                </div>
            </div>
            <div v-else>
                <div class="latest-value__value">N/A</div>
                <div class="latest-value__units">{{ units }}</div>
            </div>
        </div>
    </div>
</template>

<script>

import DefaultCard_1LatestValue from "../default/DefaultCard_1LatestValue";

export default {
    name: "GerkonCard_1LatestValue",
    extends: DefaultCard_1LatestValue,
    computed: {
        deviceImage() {
            const deviceSlug = this.deviceObject?.getType()?.getSlug();

            try {
                const iconFile = require(`../../../assets/metrics/${deviceSlug}.png`);

                return {
                    "background-image": `url(${iconFile})`
                }
            } catch (e) {
                return null;
            }
        },
        status() {
            return this.intValue === 1;
        }
    },
}
</script>

<style lang="less" scoped>
@import "~animate.css";

.latest-value {
    width: 100%;
    padding: 0;

    &__bg {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 170px;
        height: 170px;
        background-color: #ccc;
        border-radius: 100%;
        color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__value {
        text-align: center;
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 1;
        color: #fff;
    }

    &__units {
        text-align: center;
        font-weight: 500;
        font-size: 1.2rem;
        color: #fff;
    }

    .status-icon {
        background-color: white;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
    }
}
</style>
