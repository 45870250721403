<template>
    <div :style="{backgroundColor:  chartColor || '#e3e3e3' }" class="gerkon-statistic">
        <div v-for="(item, index) in slicedTimeseries" :key="index" class="mb-2 pl-1 row">
            <div class="text-nowrap pl-1" style="width: 40%">
                <div v-if="classStatus0 && classStatus1" class="gerkon-statistic-icon d-inline-flex justify-content-center align-items-center mr-2">
                    <i v-if="item.value === 0" class="fas" :class="classStatus0"></i>
                    <i v-if="item.value === 1" class="fas" :class="classStatus1"></i>
                </div>
                <span v-if="item.value === 0">{{ textStatus0 }}</span>
                <span v-else-if="item.value === 1">{{ textStatus1 }}</span>
                <span v-else>N/A</span>
            </div>
            <div class="text-nowrap pl-1" style="width: 30%">
                <i class="fas fa-calendar-alt"></i>
                <strong> {{ item.date }}</strong>
            </div>
            <div class="text-nowrap pl-1" style="width: 30%">
                <i class="far fa-clock"></i>
                <strong> {{ item.time }}</strong>
            </div>
        </div>
    </div>
</template>

<script>

import DefaultCard_1AbstractChart from "../default/DefaultCard_1AbstractChart";
import moment from "moment-mini";
import config from "../../../config";

export default {
    name: "GerkonCard_1Table",
    extends: DefaultCard_1AbstractChart,
    data() {
        return {
            textStatus1: this.$t('Открылось:'),
            textStatus0: this.$t('Закрылось:'),
            classStatus1: "fa-unlock text-danger",
            classStatus0: "fa-lock text-success",
        }
    },
    computed: {
        metricTimeseries() {
            return this.timeseries.find(item => item.getSlug() === "status")?.getData();
        },
        slicedTimeseries() {
            return this.metricTimeseries?.slice(0, 6)?.map(item => {
                return {
                    date: moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY),
                    time: moment(item.getDate()).format(config.dateTimeFormats.HHmmss),
                    value: parseInt(item?.getValue())
                };
            });
        },
    },
}
</script>

<style scoped lang="less">

.gerkon-statistic {
    border-radius: 10px;
    height: 190px;
    padding: 1rem 1.5rem;
    font-size: 13px;
    color: #fff;

    &-item {
        margin: 5px 0;
    }

    &-icon {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background-color: white;
    }

    &-time {
        min-width: 80px;
        font-weight: 600;
        line-height: 14px;
    }
}
</style>
