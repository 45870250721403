<template>
    <div>
        <div class="d-flex justify-content-around metric py-2">
            <div v-for="(metric, index) in paginatedData"
                 :key="index"
                 class="metric-buttons d-flex align-items-center justify-content-center flex-column"
                 :class="{ active : isSelected(metric) }"
                 @click="$emit('change', metric)">
                <Icon :icon-name="metric.getIcon()"
                      width="24"
                      height="24"
                      :fill="metric.getColor()"
                      viewBox="0 0 26 26"/>

                <div class="metric-data py-1"
                     :style="isSelected(metric) ? `color: ${metric.getColor()}` : 'color: #706C6C'">
                    <span v-if="metric.getSlug() === 'status'">
                        <span v-if="intValue(metric) === 1">{{ textStatus1 }}</span>
                        <span v-else-if="intValue(metric) === 0">{{ textStatus0 }}</span>
                        <span v-else>N/A</span>
                    </span>
                    <span v-else>
                        {{ getMetricLabel(metric, 0) }}
                    </span>
                </div>

                <Icon class="d-block mx-auto"
                      v-if="isSelected(metric)"
                      icon-name="dot"
                      :fill=metric.getColor()
                      width="7"
                      height="7"/>

                <div v-else style="height: 7px"></div>
            </div>
        </div>
    </div>
</template>

<script>

import DefaultCard_2MetricsButtons from "../default/DefaultCard_2MetricsButtons";

export default {
    name: "GerkonCard_2MetricsButtons",
    extends: DefaultCard_2MetricsButtons,
    data() {
        return {
            textStatus1: this.$t('Открыто'),
            textStatus0: this.$t('Закрыто'),
        }
    },
    methods: {
        intValue(metric) {
            const value = this.timeseries.find(item => item.getSlug() === metric?.getSlug())?.getLatestData()?.getValue();
            return parseInt(value);
        }
    }
}
</script>
