<template>
    <div ref="latestValue" class="latest-value">
        <div v-if="value !== null">
            <div v-if="currentMetric.getSlug() === 'status'"
                 class="d-flex justify-content-center align-content-center text-center">
                <div>
                    <div class="status-icon mr-2">
                        <i v-if="status" class="fas fa-lg fa-unlock text-danger"></i>
                        <i v-else class="fas fa-lg fa-lock text-success"></i>
                    </div>
                    <div class="d-inline-flex justify-content-center align-items-center font-weight-bold mt-1 text-uppercase text-white">
                        {{ status ? $t('Открыто') : $t('Закрыто') }}
                    </div>
                </div>
            </div>
            <div v-else-if="currentMetric.getSlug() === 'times'">
                <div class="latest-value__value">{{ Math.round(value) }}</div>
            </div>
            <div v-else>
                <div class="latest-value__value">{{ Math.round(value) }}</div>
                <div class="latest-value__units">{{ units }}</div>
            </div>
        </div>
        <div v-else>
            <div class="latest-value__value">N/A</div>
            <div class="latest-value__units">{{ units }}</div>
        </div>
    </div>
</template>

<script>

import GerkonCard_1LatestValue from "./GerkonCard_1LatestValue";

export default {
    name: "GerkonCard_2LatestValue",
    extends: GerkonCard_1LatestValue,
}
</script>

<style lang="less" scoped>
.latest-value {
    width: 100%;
    padding: 10px 0 0;

    .status-icon {
        display: inline-block;
        background: #fff;
        padding: 5px 10px;
        border-radius: 5px;
    }

    &__bg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #ccc;
        color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__value {
        text-align: center;
        font-weight: 700;
        font-size: 2rem;
        line-height: 1;
        color: #fff;
    }

    &__units {
        text-align: center;
        font-weight: 500;
        font-size: 1.2rem;
        color: #fff;
    }
}

</style>
