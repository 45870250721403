<template>
    <div class="row mb-2" style="min-height: 140px">
        <div class="col-12 position-relative">
            <div class="list-group">
                <div class="list-group-item px-2 list-group-item-action font-weight-bold justify-content-between align-items-center"
                     v-for="(metric, index) in paginatedData"
                     :key="index"
                     :class="{ active : isSelected(metric) }"
                     @click="$emit('change', metric)">
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="pr-2">
                            <Icon :icon-name="metric.getIcon()"
                                  width="23"
                                  height="23"
                                  :fill="metric.getColor()"/>

                            <div class="mt-1" style="height: 4px"></div>
                        </div>
                        <div v-if="metric.getName().length > 20"
                             class="list-group-item-title"
                             data-toggle="tooltip"
                             :title="metric.getName()">
                            {{ stringLimit(metric.getName(), 20) }}
                        </div>
                        <div v-else class="list-group-item-title">
                            {{ metric.getName() }}
                        </div>
                    </div>
                    <div class="list-group-item-value">
                        <div v-if="metric.getSlug() === 'status' && getLatestStatusValue(metric) !== null">
                            <div class="d-flex align-items-center">
                                <div>
                                    {{ getLatestStatusValue(metric) === 0 ? textStatus0 : textStatus1 }}
                                </div>
                                <div class="ml-2">
                                    <i v-if="getLatestStatusValue(metric) === 0" class="fas fa-lg" :class="classStatus0"></i>
                                    <i v-else class="fas fa-lg" :class="classStatus1"></i>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            {{ getMetricLabel(metric, 0) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Icon from "../../Icon";
import DefaultCard_3ValuesList from "../default/DefaultCard_3ValuesList";

export default {
    name: "GerkonCard_3ValuesList",
    extends: DefaultCard_3ValuesList,
    components: {
        Icon,
    },
    data() {
        return {
            textStatus1: this.$t('Открыто'),
            textStatus0: this.$t('Закрыто'),
            classStatus1: "fa-unlock text-danger",
            classStatus0: "fa-lock text-success",
        }
    },
    methods: {
        getLatestStatusValue() {
            const value = this.timeseries
                ?.find(item => item.getSlug() === "status")
                ?.getLatestData()
                ?.getValue();

            return parseInt(value);
        }
    }
}
</script>

<style lang="less" scoped>

@import "../../../less/_variables";

.list-group {
    border-radius: 0;

    &-item {
        display: flex;
        border-color: transparent;
        padding: 0.45rem 1.25rem;
        font-size: 0.9rem;
        border-radius: .5rem;

        &:hover {
            background: white;
        }

        &-title {
            font-weight: 500;
        }

        &-value {
            font-weight: 600;
            color: #706C6C;
        }

        &.active {
            color: #495057;
            background: transparent;
        }

        .status-icon {
            background: #FFFFFF;
            border-radius: 3px;
            width: 30px;
            height: 30px;
        }
    }
}

</style>
